.App {
  .TimerButton .MuiButton-startIcon {
    margin-right: 0 !important;
  }
  .TimerButton .timer-value {
    font-weight: 600;
    letter-spacing: 1px;
    font-variant-numeric: tabular-nums;
    overflow: hidden;
    width: 0;
    animation: enlarge 380ms forwards ease-out;
  }

  .LoginPage {
    background-image: url(./assets/images/background-medium.jpg);
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1200;

    .form-box {
      h5 {
        font-weight: 600;
        letter-spacing: 1px;
      }
      .MuiTextField-root,
      .MuiInput-root {
        color: #fff;
      }
      .MuiInputLabel-root {
        color: rgba(255, 255, 255, 0.8);
      }
      .MuiInput-underline::before {
        border-bottom-color: rgba(255, 255, 255, 0.8);
      }
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      background: linear-gradient(
        to top left,
        rgba(50, 50, 50, 0.4),
        rgba(100, 100, 100, 0.6)
      );
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }

  .clear-filter-btn {
    margin-left: 16px;
    text-transform: capitalize;
    .MuiButton-startIcon {
      margin-right: 0 !important;
    }
  }

  .LoadingBar {
    position: fixed;
    height: 8px;
    top: -2px;
    left: 0;
    right: 0;
    border-radius: 0;
    z-index: 90000;
    animation: pulseWithColor 1.5s ease-in-out infinite;
  }
  .LoadingRow > .MuiTableCell-root {
    border-bottom: none;
    .MuiSkeleton-root {
      border-radius: 6px;
    }
  }
}

.p175 {
  padding: 14px;
}

.py175 {
  padding-top: 14px;
  padding-bottom: 14px;
}

.px175 {
  padding-left: 14px;
  padding-right: 14px;
}

.pt175 {
  padding-top: 14px;
}

.pb175 {
  padding-bottom: 14px;
}

.pl175 {
  padding-left: 14px;
}

.pr175 {
  padding-right: 14px;
}

.m175 {
  margin: 14px;
}

.my175 {
  margin-top: 14px;
  margin-bottom: 14px;
}

.mx175 {
  margin-left: 14px;
  margin-right: 14px;
}

.mt175 {
  margin-top: 14px;
}

.mb175 {
  margin-bottom: 14px;
}

.ml175 {
  margin-left: 14px;
}

.mr175 {
  margin-right: 14px;
}

.descTitle {
  padding: 0 14px 14px 14px;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 5px;
}

p.segLabel {
  letter-spacing: 1px;
  font-size: 0.75rem;
  font-weight: 800;
  text-transform: uppercase;
  opacity: 0.75;
  margin-top: 8px;
  margin-bottom: 16px;
}

@keyframes pulseWithColor {
  0% {
    opacity: 1;
    background-color: #e91e63;
  }
  40% {
    background-color: #f44336;
    // background-color: #ff9800;
  }
  50% {
    opacity: 0.4;
    // background-color: #9c27b0;
    background-color: #43a047;
  }
  60% {
    background-color: #f44336;
    // background-color: #ff9800;
  }
  100% {
    opacity: 1;
    background-color: #e91e63;
  }
}

.AuditHistory {
  .MuiListItem-root {
    cursor: default;
    border-bottom: 1px solid rgba($color: #888, $alpha: 0.2);
  }
  .MuiListItem-root:last-child {
    border-bottom: none;
  }
}

.MuiTableBody-root
  > .MuiTableRow-root:last-child
  .MuiTableCell-root.MuiTableCell-body {
  border-bottom-color: transparent;
}

.e360-number {
  letter-spacing: 1px !important;
  white-space: nowrap !important;
}

.e360-id {
  letter-spacing: 1px !important;
  white-space: nowrap !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.py-4 {
  padding: 4px 0;
}
.py-5 {
  padding: 5px 0;
}
.py-6 {
  padding: 6px 0;
}

.pt-4 {
  padding-top: 4px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-6 {
  padding-top: 6px;
}

.pb-4 {
  padding-bottom: 4px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-6 {
  padding-bottom: 6px;
}

.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-inline-flex {
  display: inline-flex;
}
.d-none {
  display: none;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-baseline {
  align-items: baseline;
}
.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-stretch {
  align-self: stretch;
}
.flex-grow {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-rev {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-row {
  flex-direction: row;
}
.flex-row-rev {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-rev {
  flex-direction: column-reverse;
}

.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

@keyframes enlarge {
  0% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@media (hover: none) {
  .App .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: transparent;
  }
}
